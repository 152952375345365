export default {
  data() {
    return {
      validate: {
        collectionDate: { isValid: true, errMsg: "" },
        issueDate: { isValid: true, errMsg: "" },
        registMember: { isValid: true, errMsg: "" },
        generateMember: { isValid: true, errMsg: "" },
        jwnetItem: { isValid: true, errMsg: "" },
        route: { isValid: true, errMsg: "" },
        wasteName: { isValid: true, errMsg: "" },
        detailWasteName: { isValid: true, errMsg: "" },
        quantityValue: { isValid: true, errMsg: "" },
        quantityConfirmBusinessTypeId: { isValid: true, errMsg: "" },
        shapeTypeId: { isValid: true, errMsg: "" },
        shapeQuantity: { isValid: true, errMsg: "" },
        contactNo: [{ isValid: true, errMsg: "" }],
        remarks: [{ isValid: true, errMsg: "" }],
        collectCost: [{ isValid: true, errMsg: "" }],
        disposalCost: [{ isValid: true, errMsg: "" }],
        valuablesCost: [{ isValid: true, errMsg: "" }],
        conversionFactorKg: { isValid: true, errMsg: "" },
        conversionFactorM3: { isValid: true, errMsg: "" },
        conversionFactorLiter: { isValid: true, errMsg: "" },
        conversionFactorUnitNumber: { isValid: true, errMsg: "" },
      },
    };
  },
  methods: {
    validatesCheck() {
      const v = this.validate;
      const w = this.formValue.wasteInfo;
      const m = this.formValue.manifestInfo;
      const wasteQuantityRegexp = new RegExp(/^(\d{1,5}|0)(\.\d{1,3})?$/);
      const halfAlphaRegexp = new RegExp(/^[A-Za-z0-9]*$/);
      let isValidForm = true;

      if (!v.collectionDate.isValid) {
        v.collectionDate.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.issueDate.isValid) {
        v.issueDate.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.registMember.isValid) {
        v.registMember.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.generateMember.isValid) {
        v.generateMember.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (m.generateMember) {
        if (m.generateMember.name && m.generateMember.name.length > 12) {
          v.generateMember.errMsg = "12文字以内で入力してください。"
          isValidForm = false;
        }
      }
      if (m.registMember) {
        if (m.registMember.name && m.registMember.name.length > 12) {
          v.registMember.errMsg = "12文字以内で入力してください。"
          isValidForm = false;
        }
      }
      if (this.formValue.collectCost && !v.collectCost.isValid) {
        v.collectCost.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (this.formValue.disposalCost && !v.disposalCost.isValid) {
        v.disposalCost.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (this.formValue.valuablesCost && !v.valuablesCost.isValid) {
        v.valuablesCost.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.jwnetItem.isValid) {
        v.jwnetItem.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.route.isValid) {
        v.route.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.wasteName.isValid) {
        v.wasteName.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (w.detailWasteName && !v.detailWasteName.isValid) {
        v.detailWasteName.errMsg = "詳細名称は30文字以内で入力してください。";
        isValidForm = false;
      }
      if (w.wasteQuantity) {
        if (!wasteQuantityRegexp.test(w.wasteQuantity)) {
          v.quantityValue.errMsg =
            "整数5桁、小数点1桁、小数部3桁で入力してください。";
          isValidForm = false;
        }
        if (w.wasteQuantity == 0.0) {
          v.quantityValue.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
      } else {
        v.quantityValue.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.quantityConfirmBusinessTypeId.isValid) {
        v.quantityConfirmBusinessTypeId.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.shapeTypeId.isValid) {
        v.shapeTypeId.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (w.shapeQuantity) {
        if (String(w.shapeQuantity).length > 5) {
          v.shapeQuantity.errMsg =
            "荷姿数量は5桁以内の数値で入力してください。";
          isValidForm = false;
        }
        if (!Number.isInteger(Number(w.shapeQuantity))) {
          v.shapeQuantity.errMsg = "荷姿数量は整数で入力してください。";
          isValidForm = false;
        }
        if(Number(w.shapeQuantity) <= 0) {
          v.shapeQuantity.errMsg = "荷姿数量は0より大きい値を設定してください。";
          isValidForm = false;
        }
      }
      v.contactNo.map((cv, index) => {
        const f = this.formValue.contactNoInfo[index];
        if (f.contactNo !== "") {
          if (f.contactNo.length && !cv.isValid) {
            cv.errMsg = "半角英数字で入力してください。";
            isValidForm = false;
          }
          if (!halfAlphaRegexp.test(f.contactNo)) {
            cv.errMsg = "半角英数字で入力してください。";
            isValidForm = false;
          }
          if (
            encodeURIComponent(f.contactNo).replace(/%../g, "x").length > 20
          ) {
            cv.errMsg = "20バイト以内で入力してください。";
            isValidForm = false;
          }
        }
      });

      v.remarks.map((re, index) => {
        const r = this.formValue.remarksInfo[index];
        if (r.detail !== "") {
          if (this.countByteByString(this.toFullWidthString(r.detail)) > 50) {
            re.errMsg = "備考は50バイト以内で入力してください。";
            isValidForm = false;
          }
        }
      });

      return isValidForm;
    },

    runValidate() {
      return this.validatesCheck();
    },

    // 半角は1Byte・全角2Byte文字を分けてカウントして、Byteに変換する(Byte数はShift-jis)
    countByteByString(string) {
      let byteSize = 0;
      // 半角・全角判定
      const halfAlphaRegexp = new RegExp(/^[A-Za-z0-9/]*$/);

      // 文字列１つ１つを取得し、半角か全角か判定する
      for (let i = 0; i < string.length; i++) {
        let character = string.charAt(i);

        if (halfAlphaRegexp.test(character)){
          byteSize += 1;
        } else {
          byteSize += 2;
        }
      }
      return byteSize;
    },

  },
};

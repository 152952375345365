export default {
  data() {
    return {
      formValue: {
        manifestInfo: {
          manifestNo: 123,
          manifestStatusInfo: {
            id: null,
            name: "",
          },
          collectionDate: "",
          collectionTime: "",
          issueDate: "",
          generateCompanyInfo: {
            id: null,
            name: "",
          },
          generateStoreInfo: {
            id: null,
            name: "",
          },
          registMember: {
            id: null,
            name: "",
          },
          generateMember: {
            id: null,
            name: "",
          },
          manifestUpdateDate: "",
          conversionFactorKg: null,
          conversionFactorM3: null,
          conversionFactorLiter: null,
          conversionFactorUnitNumber: null,
          attachedFilePath: "",
          attachedFileName: "",
        },
        routeInfo: {
          routeId: null,
          generateStore: {
            id: null,
            name: "",
          },
          collectCompanys: [
            {
              id: null,
              name: "",
            },
          ],
          disposalSite: {
            id: null,
            name: "",
          },
          jwnetItem: {
            id: null,
            name: "",
          },
          wdsFileName: "",
        },
        labelInfo: {
          manifestLabels: [
            {
              labelId: null,
              labelHeader: "",
              labelTypeId: null,
              labelValue: "",
              labelUnit: "",
            },
          ],
        },
        wasteInfo: {
          wasteName: {
            id: null,
            name: "",
          },
          wasteQuantity: null,
          wasteUnitType: {
            id: null,
            name: "",
          },
          quantityConfirmBusinessType: {
            id: null,
            name: "",
          },
          shapeType: {
            id: null,
            name: "",
          },
          disposalWayTypeId: null,
          shapeQuantity: null,
        },
        contactNoInfo: [
          {
            id: null,
            contactNo: "",
          },
        ],
        collectCost: null,
        disposalCost: null,
        valuablesCost: null,
        remarksInfo: [
          {
            id: null,
            detail: "",
          },
        ],
        harmfulList: [
          {
            id: null,
            name: "",
          },
        ],
        collectReportList: [
          {
            collectionReportDate: "",
            section: null,
            collectCompany: {
              legalPersonalityTypeId: null,
              legalPersonalityTypeName: "",
              id: null,
              name: "",
            },
            collectMemberInfo: {
              id: null,
              name: "",
            },
            reportMemberInfo: {
              id: null,
              name: "",
            },
            collectWay: {
              id: null,
              name: "",
            },
            carNo: "",
            collectQuantity: null,
            collectWasteUnitType: {
              id: null,
              name: "",
            },
            valuablesQuantity: null,
            collectFactoryInfo: {
              id: null,
              name: "",
            },
            destinationInfo: {
              destinationTypeInfo: {
                id: null,
                name: "",
              },
              id: null,
              name: "",
              addressInfo: {
                zipCode: "",
                prefecturesId: null,
                address: "",
                buildingName: "",
              },
            },
            remark: "",
          },
        ],
        disposalReportInfo: {
          wasteReceiptDate: "",
          disposalReportDate: "",
          disposalCompany: {
            legalPersonalityTypeId: null,
            legalPersonalityTypeName: "",
            id: null,
            name: "",
          },
          disposalMemberName: "",
          reportMemberName: "",
          disposalWayType: {
            id: null,
            name: "",
          },
          acceptQuantity: null,
          acceptWasteUnitType: {
            id: null,
            name: "",
          },
          remark: "",
        },
        directInput: "",
      },
    };
  },

  methods: {
    formatEditData() {
      const form = this.formValue;
      if (this.isGenerateMemberSelect) {
        const member = this.memberDisOpt.find(e => e.id === form.manifestInfo.generateMember.id)
        form.manifestInfo.generateMember = member ? { id: member.id, name: member.name } : null
      } else {
        form.manifestInfo.generateMember.name = this.toFullWidthString(this.removeWhitespaceIfOnlyWhitespace(form.manifestInfo.generateMember.name))
        form.manifestInfo.generateMember.id = null
      }

      if (this.isRegistMemberSelect) {
        const member = this.memberOpt.find(e => e.id === form.manifestInfo.registMember.id)
        form.manifestInfo.registMember = member ? { id: member.id, name: member.name } : null
      } else {
        form.manifestInfo.registMember.name = this.toFullWidthString(this.removeWhitespaceIfOnlyWhitespace(form.manifestInfo.registMember.name))
        form.manifestInfo.registMember.id = null
      }

      return new Promise((resolve) => {
        const formattedData = {
          manifestEditTypeId: 3, // 3は編集
          manifestEditInfo: {
            collectionDate: form.manifestInfo.collectionDate,
            collectionTime: form.manifestInfo.collectionTime,
            registMember: form.manifestInfo.registMember,
            generateMember: form.manifestInfo.generateMember,
            routeId: form.routeInfo.routeId,
            conversionFactorKg: form.manifestInfo.conversionFactorKg,
            conversionFactorM3: form.manifestInfo.conversionFactorM3,
            conversionFactorLiter: form.manifestInfo.conversionFactorLiter,
            conversionFactorUnitNumber: form.manifestInfo.conversionFactorUnitNumber,
            attachedFilePath: form.manifestInfo.attachedFilePath,
            attachedFileName: form.manifestInfo.attachedFileName,
          },
          contactNoList: form.contactNoInfo.filter(cn => cn.contactNo !== "")
              .map((contactNo) => { return {"contactNo": contactNo.contactNo}; }),
          collectCost: form.collectCost,
          disposalCost: form.disposalCost,
          valuablesCost: form.valuablesCost,
          wasteEditInfo: {
            jwnetItemTypeId: form.routeInfo.jwnetItem.id,
            wasteNameId: form.wasteInfo.wasteName.id,
            detailWasteName: form.wasteInfo.detailWasteName !== null ? this.toFullWidthString(form.wasteInfo.detailWasteName) : null,
            wasteQuantity: form.wasteInfo.wasteQuantity,
            wasteUnitTypeId: form.wasteInfo.wasteUnitType.id,
            quantityConfirmBusinessTypeId:
              form.wasteInfo.quantityConfirmBusinessType.id,
            shapeTypeId: form.wasteInfo.shapeType.id,
            shapeQuantity: parseInt(form.wasteInfo.shapeQuantity),
            disposalWayTypeId: form.wasteInfo.disposalWayTypeId,
          },
          // harmfulInfoのidがnullなら削除
          harmfulEditList: form.harmfulList.filter(hf => hf.id).map((harmful) => {
            return {
              id: harmful.id,
            };
          }),
          labelEditInfo: {
            labels: [...form.labelInfo.manifestLabels],
          },
          remarksEditInfo: {
            remarksList: form.remarksInfo.filter(r => r.detail !== "").map((remark) => {
              return {
                remarks: this.toFullWidthString(remark.detail),
              };
            }),
          },
          anyGenerateStoreInfo: form.anyGenerateStoreInfo,
          directInput: form.directInput,
        };

        // 引渡し日の時間指定なし
        if (formattedData.manifestEditInfo.collectionTime === '') {
          delete formattedData.manifestEditInfo.collectionTime;
        }

        resolve(formattedData);
      });
    },
  },
};
